import React from 'react'
import { css } from '@emotion/core'
import { useTheme } from './Theming'

import { FacebookShareButton } from 'react-share'

const Share = ({ url, title,facebookHandle }) => {
  const theme = useTheme()
  return (
    <>

     
      <div
      css={css`
        display: flex;
        width:100%;
        justify-content:flex-end;
        margin:30px 0;
        `}>
           <span css={css`
           display:block;
        font-size:.8rem;
        padding-top:2px;
        padding-right:5px;
       
        color:${theme.colors.text};
        `
      }
      >Share article</span>
  
     
      &rarr;&nbsp;&nbsp;
      
      <FacebookShareButton
        url={url}
        quote={title}
        via={facebookHandle}
        css={css`
          cursor: pointer;
        `}
      >
      <span style={{fontSize:'.8rem'}}>Facebook</span>
      </FacebookShareButton>

      
    </div>
    </>
  )
}

export default Share
